<template>
    <div>
        <div class="add-to-favorites-container" v-if="display != 'block'">
            <div v-if="!added" class="add-to-favorites" @click="toggle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation"
                    focusable="false"
                    style="display: block; fill: rgba(0, 0, 0, 0.5); height: 24px; width: 24px; stroke: #FFF; stroke-width: 2; overflow: visible;">
                    <path
                        d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z">
                    </path>
                </svg>
                <!-- <i class="fal fa-heart ms-1"></i> -->
            </div>
            <div v-if="added" class="in-favorites" @click="toggle">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation"
                    focusable="false"
                    style="display: block; fill: red; height: 24px; width: 24px; stroke: #FFF; stroke-width: 2; overflow: visible;">
                    <path
                        d="M16 28c7-4.73 14-10 14-17a6.98 6.98 0 0 0-7-7c-1.8 0-3.58.68-4.95 2.05L16 8.1l-2.05-2.05a6.98 6.98 0 0 0-9.9 0A6.98 6.98 0 0 0 2 11c0 7 7 12.27 14 17z">
                    </path>
                </svg>
                <!-- <i class="fa fa-heart ms-1"></i> -->
            </div>
        </div>
        <div v-if="display == 'block'">
            <div class="col-12">
                <button v-if="added" type="button" class="btn btn-secondary btn-block btn-sm btn-fav"
                    @click="toggle">Skelbimas išsaugotas <i class="fa fa-heart ms-1"></i></button>
                <button v-if="!added" type="button" class="btn btn-primary btn-block btn-sm btn-fav"
                    @click="toggle">Išsaugoti skelbimą <i class="fa fa-heart ms-1"></i></button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id', 'slug', 'display'],

    data() {
        return {
            added: false,
        }
    },

    mounted() {
        axios.get('/skelbimas/' + this.slug + '/' + this.id + '/issaugoti/tikrinti').then(res => {
            this.added = res.data.added
        }).catch(err => {
            console.log(err.response)
        })
    },

    methods: {
        toggle() {
            axios.post('/skelbimas/' + this.slug + '/' + this.id + '/issaugoti').then(res => {
                (this.added) ? this.added = false : this.added = true
                // console.log(this.added)
            }).catch(err => {
                console.log(err.response)
            })
        }
    }
}
</script>
<style media="screen">
.add-to-favorites:hover {
    color: #BC243C;
    cursor: pointer;
}

.in-favorites {
    color: #BC243C;
    cursor: pointer;
}
</style>

<template>
    <div>
        <star-rating :star-size="30" v-model="rating"/>
        <input type="hidden" name="vote" v-model="rating">
    </div>
</template>
<script>
    import StarRating from 'vue-star-rating'

    export default{
        components:{
            StarRating
        },
        data(){
            return{
                rating: 5
            }
        }
    }
</script>

import Vue from 'vue'
import Lightbox from '@morioh/v-lightbox'
import VuejsDialog from 'vuejs-dialog';
import Notifications from 'vue-notification'

require('./bootstrap');

require('daterangepicker');

window.Vue = require('vue');

Vue.component('listing-card-gallery', require('./components/listing/listingCardGallery.vue').default);
Vue.component('listing-gallery', require('./components/listing/gallery.vue').default);
Vue.component('listing-modern-gallery', require('./components/listing/modernGallery.vue').default);
Vue.component('listing-gallery-food', require('./components/listing/gallery-food.vue').default);
Vue.component('listing-photos', require('./components/listing/photos.vue').default);
Vue.component('listing-autocomplete', require('./components/listing/google-autocomplete.vue').default);
Vue.component('listing-services', require('./components/listing/services.vue').default);
// Vue.component('listing-location', require('./components/listing/location.vue').default);
// Vue.component('listing-locations', require('./components/listing/locations.vue').default);
Vue.component('listing-favorites', require('./components/listing/favorites.vue').default);
Vue.component('listing-rating', require('./components/listing/rating.vue').default);
Vue.component('listing-display-rating', require('./components/listing/display-rating.vue').default);
Vue.component('listing-extra-services', require('./components/listing/extra-services.vue').default);
// Vue.component('listing-breeds', require('./components/listing/breeds.vue').default);
// Vue.component('listing-work-hours', require('./components/listing/work-hours.vue').default);
// Vue.component('listing-hours', require('./components/listing/listing-hours.vue').default);
Vue.component('listing-hours', require('./components/listing/listing-hours.vue').default);
Vue.component('listing-hours', require('./components/listing/listing-hours.vue').default);

Vue.use(Lightbox);
Vue.use(VuejsDialog, {
    html: true,
    loader: true,
    okText: 'Tęsti',
    cancelText: 'Atšaukti',
    animation: 'fade',
    message: 'Ar tikrai norite atlikti šį veiksmą?'
});
Vue.use(Notifications)

import draggable from 'vuedraggable'
Vue.use(draggable)

// Vue.use(VueGoogleAutocomplete);

const app = new Vue({
    el: '#app',
});

//Custom code
$(document).ready(function () {
    if ($('.request-from-picker').length) {
        $('.request-from-picker').daterangepicker({
            "locale": {
                "format": "YYYY-MM-DD",
                "separator": " - ",
                "applyLabel": "Pasirinkti",
                "cancelLabel": "Atšaukti",
                "fromLabel": "Nuo",
                "toLabel": "Iki",
                "customRangeLabel": "Custom",
                "weekLabel": "W",
                "daysOfWeek": [
                    "Sek",
                    "Pir",
                    "Ant",
                    "Tre",
                    "Ket",
                    "Pen",
                    "Šešt"
                ],
                "monthNames": [
                    "Sausis",
                    "Vasaris",
                    "Kovas",
                    "Balandis",
                    "Gegužė",
                    "Birželis",
                    "Liepa",
                    "Rugpjūtis",
                    "Rugsėjis",
                    "Spalis",
                    "Lapkritis",
                    "Gruodis"
                ],
                "firstDay": 1
            },
        });
    }

    $('#logoinput').change(function () {
        var file = $('#logoinput')[0].files[0].name;
        $('#logoname').text(file);
    });

    $('#coverinput').change(function () {
        var cover = $('#coverinput')[0].files[0].name;
        $('#covername').text(cover);
    });
})
$('#promo_points').on('show.bs.modal', function (e) {
    var listingID = $(e.relatedTarget).data('listing');

    $(e.currentTarget).find('input[name="listing_id"]').val(listingID);
});

$('#filter_form select').change(function (event) {
    $('#filter_form').submit()
});
$('#filter_form input').change(function (event) {
    $('#filter_form').submit()
});

$('.action-call').click(function () {
    let slug = $(this).data('listing-slug');
    let id = $(this).data('listing-id');

    axios.post('/skelbimas/' + slug + '/' + id + '/skambinti').then(res => {
        console.log(res.data.message)
    }).catch(err => {
        console.log(err)
    })
});
// $('#sidebar_filter select').on('change', function(){
//     $('#sidebar_filter').submit()
//     alert(2)
// })

<template>
    <div>
        <star-rating :star-size="16" v-model="rating" :read-only="true" :show-rating="false"/>
    </div>
</template>
<script>
    import StarRating from 'vue-star-rating'

    export default{
        props: ['rating'],
        components:{
            StarRating
        }
    }
</script>

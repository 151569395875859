<template>
    <div>
        <a :href="'#darbo-laikas-' + location.id" data-toggle="modal" :data-target="'#darbo-laikas-' + location.id"><i class="fal fa-clock mr-3"></i>Darbo laikas</a>
         <div class="modal fade" :id="'darbo-laikas-' + location.id" tabindex="-1" :aria-labelledby="'darbo-label' + location.id" aria-hidden="true">
             <div class="modal-dialog rounded-0">
                 <div class="modal-content rounded-0">
                     <div class="modal-header">
                         <h5 class="modal-title" :id="'darbo-label' + location.id">{{ location.name }} Darbo laikas</h5>
                         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                             <span aria-hidden="true">&times;</span>
                         </button>
                     </div>
                     <div class="modal-body">
                         <table class="table">
                             <tr>
                                 <td>Pirmadienis</td>
                                 <td class="text-right" v-if="location.monday_start && location.monday_end">{{ location.monday_start + ' - ' + location.monday_end }}</td>
                                 <td class="text-right" v-if="!location.monday_start && !location.monday_end">Nenurodyta</td>
                             </tr>
                             <tr>
                                 <td>Antradienis</td>
                                 <td class="text-right" v-if="location.tuesday_start && location.tuesday_end">{{ location.tuesday_start + ' - ' + location.tuesday_end }}</td>
                                 <td class="text-right" v-if="!location.tuesday_start && !location.tuesday_end">Nenurodyta</td>
                             </tr>
                             <tr>
                                 <td>Trečiadienis</td>
                                 <td class="text-right" v-if="location.wednesday_start && location.wednesday_end">{{ location.wednesday_start + ' - ' + location.wednesday_end }}</td>
                                 <td class="text-right" v-if="!location.wednesday_start && !location.wednesday_end">Nenurodyta</td>
                             </tr>
                             <tr>
                                 <td>Ketvirtadienis</td>
                                 <td class="text-right" v-if="location.thursday_start && location.thursday_end">{{ location.thursday_start + ' - ' + location.thursday_end }}</td>
                                 <td class="text-right" v-if="!location.thursday_start && !location.thursday_end">Nenurodyta</td>
                             </tr>
                             <tr>
                                 <td>Penktadienis</td>
                                 <td class="text-right" v-if="location.friday_start && location.friday_end">{{ location.friday_start + ' - ' + location.friday_end }}</td>
                                 <td class="text-right" v-if="!location.friday_start && !location.friday_end">Nenurodyta</td>
                             </tr>
                             <tr>
                                 <td>Šeštadienis</td>
                                 <td class="text-right" v-if="location.saturday_start && location.saturday_end">{{ location.saturday_start + ' - ' + location.saturday_end }}</td>
                                 <td class="text-right" v-if="!location.saturday_start && !location.saturday_end">Nenurodyta</td>
                             </tr>
                             <tr>
                                 <td>Sekmadienis</td>
                                 <td class="text-right" v-if="location.sunday_start && location.sunday_end">{{ location.sunday_start + ' - ' + location.sunday_end }}</td>
                                 <td class="text-right" v-if="!location.sunday_start && !location.sunday_end">Nenurodyta</td>
                             </tr>
                         </table>
                     </div>
                 </div>
             </div>
         </div>
    </div>
</template>

<script>
    export default{
        props: ['location'],
        data(){
            return {

            }
        },
        mounted(){

        }
    }
</script>

<template>
    <div>
        <draggable v-model="previews" group="people" @start="drag=true" @end="drag=false"draggable=".drag-item" class="gallery-container" @change="reOrder">
           <div v-for="item, index in previews" :key="item.url" class="drag-item">
               <img :src="item.url" class="img-list">
               <button type="button" class="btn btn-secondary btn-sm" @click="removePhoto(index, item.id)"><i class="fa fa-trash"></i></button>
           </div>
        </draggable>
        <!-- <div class="row">
            <div class="col-lg-3" v-for="item, index in previews">
                <div class="photo-preview shadow" :class="(uploading) ? 'loading' : ''" :style="{ backgroundImage: 'url(/storage/' + item.url + ')' }"></div>
                <div class="form-group text-right">
                    <button type="button" class="btn btn-secondary btn-sm" @click="removePhoto(index, item.id)"><i class="fa fa-trash"></i></button>
                    WEIGHT: {{ item.pivot.weight }}
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <input type="file" @change="getFiles" class="d-none" ref="file" accept="image/*" multiple/>
                    <button type="button" class="btn btn-outline-secondary" @click="$refs.file.click()"><i class="fal fa-image mr-2"></i> įkelti nuotraukas</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'

    export default{
        components: {
            draggable
        },
        props: ['id', 'gallery', 'service_id'],
        data(){
            return {
                formatedImages: [],
                currentPhoto: '',
                totalPhotos: 0,
                photos: [],
                previews: [],
                progressBars: [],
                uploading: false,
                newPhoto: null
            }
        },
        mounted(){
            if(this.gallery.length){
                this.gallery.forEach(item => {
                    let tmp = item
                    let prefix = null

                    if (tmp['url'][0] != '/') {
                        prefix = '/storage/'
                    } else {
                        prefix = '/storage'
                    }
                    tmp['url'] = prefix + tmp['url']

                    this.previews.push(item)
                })
            }
        },
        methods:{
            reOrder(item){
                let photo = item.moved.element.id
                let position = item.moved.newIndex

                let data = {
                    photos: this.previews
                }

                axios.post('/vartotojas/skelbimai/' + this.id +'/move-photos', data).then(res => {
                }).catch(err => {
                    console.log(err.response)
                })
            },
            removePhoto(index, photo){
                axios.delete('/vartotojas/skelbimai/' + this.id +'/photo-delete/' + photo).then(res => {
                    this.previews.splice(index, 1)
                }).catch(err => {
                    console.log(err.response)
                })

            },
            getFiles(e){
                let length = e.target.files.length
                let file  = null
                this.newPhoto = null

                for(var i = 0; i < length; i++){
                    this.photos.push(e.target.files[i])
                    this.newPhoto = e.target.files[i]

                    this.uploadPhoto(i)
                }
            },
            uploadPhoto(index){
                this.uploading = true
                let data = new FormData()
                data.append('photo', this.newPhoto)
                data.append('listing', this.id)

                if (this.service_id){
                    data.append('service_id', this.service_id)
                }

                let config = {
                    header : {
                        'Content-Type' : 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent) {
                        var percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }.bind(this)
                }

                axios.post('/vartotojas/skelbimai/' + this.id +'/photo-upload', data, config).then(res => {
                    this.uploading = false
                    let tmp = res.data
                    tmp.url = '/storage' + tmp.url

                    this.previews.push(tmp)
                }).catch(err => {
                    console.log(err.response)
                })
            },
        }
    }
</script>
<style media="screen">
    .gallery-container{
        display: grid;
        grid-template-columns: auto auto auto auto;
        column-gap: 15px;
    }
    @media(max-width: 1200px){
        .gallery-container{
            grid-template-columns: auto auto;
        }
    }
    .img-list{
        height: 250px;
        width: 100%;
        object-fit: cover;
        margin-bottom: 12px;
    }
    .drag-item{
        position: relative;
        cursor: move;
    }
    .drag-item button{
        position: absolute;
        top: 15px;
        right: 12px;
        z-index: 3;
    }
    .drag-item button:hover{
        background: red;
    }
    .photo-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 100%;
        width: 100%;
        margin-bottom: 20px;
        position: relative;
        background-size: cover;
    }
    .photo-preview:hover{
        cursor: pointer;
    }
    .loading{
        opacity: 0.6;
    }
</style>

<template>
    <div>
        <div class="form-group" v-for="item in selected_categories" v-if="item.parent_id != null">
            <h4 class="mt-4">{{ item.name }}</h4>
            <hr>
            <div class="row">
                <div class="form-group col-lg-4 mb-1" v-for="service in allservices" v-if="service.category_id == item.id && service.breed == 0">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" :id="'service-' + service.id" name="services[]" :checked="selected_services.indexOf(service.id) != -1" :value="service.id">
                        <label class="custom-control-label" :for="'service-' + service.id">{{ service.name }}</label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <h3>Nerandi paslaugos?</h3>
        <p v-if="!new_serice">
            <a href="#new" @click="new_serice = true"><i class="fal fa-arrow-right mr-2"></i>Įrašykite savo</a>
        </p>
        <div class="form-group" v-if="new_serice">
            <label>Paslaugų pavadinimas <small>(galite įrašyti daugiau nei vieną, kiekvieną atskiriant kableliu)</small> </label>
            <input type="text" class="form-control rounded-0" v-model="new_services" name="new_services">
        </div>
    </div>
</template>

<script>
    export default{
        props: ['allservices', 'listingcategory', 'listingcategories', 'listingservices'],
        data(){
            return {
                selected_categories: [],
                selected_services: [],
                new_serice: true,
                new_services: null,
            }
        },
        mounted(){
            if(this.listingcategory){
                this.selected_categories.push(this.listingcategory)
            }
            if(this.listingcategories){
                this.listingcategories.forEach(id => {
                    this.selected_categories.push(id)
                })
            }
            if(this.listingservices){
                this.listingservices.forEach(item => {
                    this.selected_services.push(item.id)
                })
            }

        },
        methods:{

        }
    }
</script>

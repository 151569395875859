<template>
    <div class="mini-product-gallery">
        <div class="mini-gallery-container">
            <a :href="path">
                <img :src="images[currentImage]" class="mini-gallery-image" />
            </a>
            <button @click="prevImage" type="button" class="arrow left-arrow" v-if="images.length > 1">‹</button>
            <button @click="nextImage" type="button" class="arrow right-arrow" v-if="images.length > 1">›</button>
        </div>
        <div class="navigation-dots" v-if="images.length > 1">
            <span v-for="( image, index ) in  images " :key="index" class="dot" :class="{ active: currentImage === index }"
                @click="setCurrentImage(index)" v-if="index < 7"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['propimages', 'path'],
    data() {
        return {
            currentImage: 0,
            images: [],
            touchStartX: 0,
            touchEndX: 0
        };
    },
    mounted() {
        if (this.propimages.length) {
            this.propimages.forEach(image => {
                if (image[0] !== '/') {
                    image = '/' + image;
                }
                this.images.push(`/storage${image}`);
            })
        }
    },
    methods: {
        nextImage() {
            this.currentImage = (this.currentImage + 1) % this.images.length;
        },
        prevImage() {
            this.currentImage = (this.currentImage + this.images.length - 1) % this.images.length;
        },
        setCurrentImage(index) {
            this.currentImage = index;
        },
        startSwipe(event) {
            this.touchStartX = event.changedTouches[0].screenX;
        },
        endSwipe(event) {
            this.touchEndX = event.changedTouches[0].screenX;
            this.handleSwipeGesture();
        },
        handleSwipeGesture() {
            if (this.touchEndX < this.touchStartX) {
                this.nextImage();
            }
            if (this.touchEndX > this.touchStartX) {
                this.prevImage();
            }
        }
    }
};
</script>

<style scoped>
.mini-product-gallery {
    width: 100%;
    position: relative;
}

.mini-gallery-container {
    position: relative;
    height: 320px;
    width: 100%;
    /* Adjust as needed */
    overflow: hidden;
}

.mini-gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: white;
    touch-action: manipulation;
}

.left-arrow {
    height: 100%;
    left: 0;
    padding-left: 10px;
}

.right-arrow {
    height: 100%;
    right: 0;
    padding-right: 10px;
}

.right-arrow:hover,
.left-arrow:hover {
    color: #BC243C;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.25);
}

.right-arrow {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.left-arrow {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.navigation-dots {
    text-align: center;
    position: absolute;
    padding-top: 10px;
    bottom: 12px;
    left: 0;
    right: 0;
}

.dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    margin: 0 3px;
    background-color: #fbfbfb;
    border-radius: 50%;
    display: inline-block;
}

.dot.active {
    background-color: #717171;
}
</style>

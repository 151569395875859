<template>
    <div class="gallery">

        <div class="gallery-multiple" v-if="images.length >= 3">
            <!-- Thumbnail on the right -->
            <div class="thumbnail right-thumbnail" @click="openPopup(0)">
                <img :src="images[0]" alt="Third Image">
            </div>
            <div class="left-thumbnails">
                <!-- Thumbnails on the left -->
                <div class="thumbnail" @click="openPopup(2)">
                    <img :src="images[2]" alt="First Image">
                </div>
                <div class="thumbnail thumb-ov" @click="openPopup(1)">
                    <img :src="images[1]" alt="Second Image">
                    <div v-if="images.length < 6" class="max-overlay">{{ images.length }}+</div>
                </div>
            </div>
        </div>
        <!-- Thumbnails -->
        <div class="thumbnails" v-if="images.length > 6">
            <div class="thumbnail" v-for="(image, index) in images" :key="index" @click="openPopup(index)"
                v-if="index > 2 && index < 8">
                <div v-if="index == 6" class="max-overlay d-md-none">{{ images.length }}+</div>
                <div v-if="index == 7" class="max-overlay">{{ images.length }}+</div>
                <img :src="image" :alt="`Image ${index + 1}`">
            </div>

        </div>
        <!-- Popup -->
        <div v-if="popupVisible" class="popup" @click.self="closePopup">
            <div class="popup-content">
                <!-- <div class="popup-content" @touchstart="handleTouchStart" @touchmove="handleTouchMove"
                @touchend="handleTouchEnd"> -->
                <img :src="images[currentImage]" :alt="`Image ${currentImage + 1}`">
                <button class="close" @click="closePopup">&times;</button>
                <button class="prev" @click="prevImage">&#10094;</button>
                <button class="next" @click="nextImage">&#10095;</button>
                <div class="image-counter">{{ currentImage + 1 }} / {{ images.length }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['propimages'],
    data() {
        return {
            images: [
                // 'http://sodybos.test/storage//gallery/vYCv0YjoPdMkn4FLGLYgUsnusP6CvN8upMhmW5HXRQvMlmAPl0mn.webp',
                // 'http://sodybos.test/storage//gallery/XLVFunbwvAryuz8DdAkXlD25FhywNcUJsY31IYQtW57OkfLyJ1ne.webp',
                // 'http://sodybos.test/storage//gallery/fugqyFpYniUK8tsIDbbDh2LbWqj34DCDmKCP8cy1gpb05CMGh6ss.webp',
                // 'http://sodybos.test/storage//gallery/2MH4h1IRwABWQJwfCMiMQNpj1A9WNGVo1efAJZBpBiA1y1gAwzds.webp',
                // 'http://sodybos.test/storage//gallery/QepWjfKN5OXkF3jodHHNQ4Ak42Sxpkr66xBaXf3nGU3a5pQjX66C.webp',
                // 'http://sodybos.test/storage//gallery/dVqXVrStcCRovC7bTdI0XjSQoOopVaBXOXCtsa7K6SUjyJRbWacR.webp',
                // 'http://sodybos.test/storage//gallery/RBoDb2m8WGHJXUGG9LryPFi7SWKb0aKoU0Q5B1MhISWwiProGydx.webp',
                // 'http://sodybos.test/storage//gallery/vYCv0YjoPdMkn4FLGLYgUsnusP6CvN8upMhmW5HXRQvMlmAPl0mn.webp',
                // 'http://sodybos.test/storage//gallery/XLVFunbwvAryuz8DdAkXlD25FhywNcUJsY31IYQtW57OkfLyJ1ne.webp',
                // 'http://sodybos.test/storage//gallery/fugqyFpYniUK8tsIDbbDh2LbWqj34DCDmKCP8cy1gpb05CMGh6ss.webp',
                // 'http://sodybos.test/storage//gallery/2MH4h1IRwABWQJwfCMiMQNpj1A9WNGVo1efAJZBpBiA1y1gAwzds.webp',
                // 'http://sodybos.test/storage//gallery/QepWjfKN5OXkF3jodHHNQ4Ak42Sxpkr66xBaXf3nGU3a5pQjX66C.webp',
                // 'http://sodybos.test/storage//gallery/dVqXVrStcCRovC7bTdI0XjSQoOopVaBXOXCtsa7K6SUjyJRbWacR.webp',
                // 'http://sodybos.test/storage//gallery/RBoDb2m8WGHJXUGG9LryPFi7SWKb0aKoU0Q5B1MhISWwiProGydx.webp'
            ],
            popupVisible: false,
            currentImage: 0,
            touchStartX: 0,
            touchEndX: 0,
        };
    },
    methods: {
        openPopup(index) {
            this.currentImage = index;
            this.popupVisible = true;
        },
        closePopup() {
            this.popupVisible = false;
        },
        nextImage() {
            this.currentImage = (this.currentImage + 1) % this.images.length;
        },
        prevImage() {
            this.currentImage = (this.currentImage - 1 + this.images.length) % this.images.length;
        },
        handleKeydown(e) {
            if (!this.popupVisible) return;
            if (e.key === 'ArrowRight') this.nextImage();
            if (e.key === 'ArrowLeft') this.prevImage();
            if (e.key === 'Escape') this.closePopup();
        },
        handleTouchStart(e) {
            this.touchStartX = e.changedTouches[0].screenX;
        },
        handleTouchMove(e) {
            this.touchEndX = e.changedTouches[0].screenX;
        },
        handleTouchEnd() {
            if (this.touchStartX - this.touchEndX > 50) {
                this.nextImage();
            }
            if (this.touchStartX - this.touchEndX < -50) {
                this.prevImage();
            }
        },
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeydown);

        if (this.propimages.length) {
            console.log(this.propimages)
            this.propimages.forEach(item => {
                //check if image first symbol is / if not add it
                if (item[0] !== '/') {
                    item = '/' + item;
                }
                this.images.push(`/storage${item}`);
            })
        }
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeydown);
    },
};
</script>

<style scoped>
.gallery-multiple {
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* Left side is half the width of the right */
    gap: 10px;
    /* Adjust the gap between the photos as needed */
}

.left-thumbnails {
    display: grid;
    grid-template-rows: 1fr 1fr;
    /* Two rows for the left thumbnails */
    gap: 10px;
    /* Same gap as the gallery for consistency */
}

.thumbnails {
    display: inline-flex;
    gap: 10px;
    margin-top: 10px;
}

.thumbnails .thumbnail {
    /* max-width: 100px; */
    width: 25%;
    /* height: 150px; */
    height: auto;
    position: relative;

}

.max-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 30px;
    color: #fff;
    padding-top: 40%;
}

.thumb-ov .max-overlay {
    padding-top: 22%;
}

@media(max-width: 768px) {
    .max-overlay {
        font-size: 20px;
        padding-top: 32%;
    }
}

@media(min-width: 768px) {
    .thumbnails .thumbnail {
        aspect-ratio: 1/1;
    }
}

@media(max-width: 768px) {
    .thumbnails .thumbnail {
        height: 90px;
    }

    /* Hide thumnails after 4th element */
    .thumbnails .thumbnail:nth-child(n+5) {
        display: none;
    }
}

.thumbnail {
    background: #f3f3f3;
    /* Example background, change as needed */
    overflow: hidden;
    /* Hide overflow */
    cursor: zoom-in;
    border-radius: 8px;
    position: relative;
}

/* Fixed height for left thumbnails */
.left-thumbnails .thumbnail {
    height: 180px;
    /* Fixed height for individual thumbnails */
}

@media(max-width: 768px) {
    .left-thumbnails .thumbnail {
        height: 120px;
        /* Fixed height for individual thumbnails */
    }
}

/* Double the height plus gap for the right thumbnail */
.right-thumbnail {
    height: calc(2 * 180px + 10px);
    /* Double the height of the left thumbnails plus one gap */
}

@media(max-width: 768px) {
    .right-thumbnail {
        height: calc(2 * 120px + 10px);
        /* Double the height of the left thumbnails plus one gap */
    }
}

/* Make images responsive within their containers */
.thumbnail img {
    width: 100%;
    /* Full width of the container */
    height: 100%;
    /* Full height of the container */
    object-fit: cover;
    /* Cover the area, potentially clipping the image */
    display: block;
}



.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}

.popup-content {
    position: relative;
    max-width: 100%;
    max-height: 100%;
}

.popup img {
    width: auto;
    max-height: 95vh;
    max-width: 100%;
    height: 100%;
    border-radius: 8px;
}

@media(max-width: 768px) {
    .popup img {
        max-height: 95vh;
        width: 100%;
        height: auto;
    }
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: transparent;
    color: white;
    font-size: 36px;
    border: none;
    cursor: pointer;
}

.prev,
.next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    color: white;
    font-size: 36px;
    border: none;
    cursor: pointer;
    user-select: none;
    touch-action: manipulation;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.image-counter {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    font-size: 18px;
}
</style>

<template>
    <div>
        <div class="form-group form__field_with_icon" v-for="item, index in list">
            <label>Pavadinimas <span class="text-danger">*</span></label>
            <i class="fa fa-arrow-right left-icon"></i>
            <input id="contact_person" type="text" class="form-control" v-model="list[index]" name="extras[]" required>
            <button type="button" class="btn btn-danger btn-sm extra-btn" @click="removeExtra(index)"><i class="fal fa-trash"></i></button>
        </div>
        <div class="form-group">
            <button type="button" class="btn btn-primary btn-sm ms-4" @click="list.push('')"><i class="fal fa-plus me-2"></i> pridėti kitą</button>
        </div>
    </div>
</template>
<script>
    export default{
        props: ['extras'],

        data(){
            return{
                list: []
            }
        },
        mounted(){
            if(this.extras){
                this.extras.forEach(item => {
                    this.list.push(item)
                })
            }
        },
        methods:{
            removeExtra(index){
                this.list.splice(index, 1)
            }
        }
    }
</script>
<style media="screen">
    .extra-btn{
        position: absolute;
        right: 18px;
        top: 12px;
        color: #fff;
    }
</style>

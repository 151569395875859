<template>
    <div>
         <lightbox :items="formatedImages" :cells="5"></lightbox>
    </div>
</template>

<script>
    export default{
        props: ['images'],
        data(){
            return {
                formatedImages: []
            }
        },
        mounted(){
            this.images.forEach(item => {
                this.formatedImages.push('/storage/' + item.url)
            })
        },
        methods:{
            next() {
                console.log('happening')
                this.show(this.index + 1);
            },
        }
    }
</script>
<style media="screen">
    .lb-grid{
        height: 600px;
    }
    .lb-modal-img{
        left: 0;
        right: 0;
    }
    @media(max-width: 992px){
        .lb-grid{
            height: 450px;
        }
    }
</style>
